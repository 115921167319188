<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg8 xl6>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-routes</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varRotaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    dense
                    hide-details="auto"
                    v-model="varRotaLocal.rota"
                    label="Rota"
                    rows="2"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("cadRotas");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varRotaLocal: {
  //     nome_rota: { required },
  //     email: { required, email },
  //     rota: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadRotasForm",
    tituloForm: "",
    varRotaLocal: {},
  }),

  computed: {
    ...mapState(["staRotaSelect", "listaUsuarios"]),
  },

  watch: {
    staRotaSelect(rotaNovo) {
      this.metSincronizar(rotaNovo);
    },
  },

  created() {
    this.metSincronizar(this.staRotaSelect);
    if (this.staRotaSelect) {
      this.tituloForm = "Editar Rota";
    } else {
      this.tituloForm = "Nova Rota";
    }
    // this.formataDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarRota",
      "actResetarRota",
      "actCriarRota",
      "actEditarRota",
      "actResetarErro",
    ]),

    /* obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },



    }, */

    selecionarRotaEdicao(rota) {
      this.actSelecionarRota({ rota });
    },

    async salvarRota() {
      switch (this.staRotaSelect) {
        case undefined:
          this.varRotaLocal.criacao = this.$store.state.login.usuario.usuario;
          this.varRotaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarRota({
            rota: this.varRotaLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadRotas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadRotas.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Rota";
            this.$root.snackbar.show({
              type: "success",
              message: "Rota Criada!",
            });
          }
          break;
        default:
          this.varRotaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varRotaLocal.criado = undefined;
          this.varRotaLocal.criacao = undefined;
          this.varRotaLocal.modificado = undefined;
          await this.actEditarRota({
            rota: this.varRotaLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.formataDatasDB();
          if (this.$store.state.cadRotas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadRotas.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Rota Salva!",
            });
          }
      }
      //this.resetar();
    },

    metSincronizar(rota) {
      this.varRotaLocal = Object.assign({}, rota || { id: null });
    },
    submit() {
      // this.$v.$touch();
      this.salvarRota();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varRotaLocal.ultima_compra = this.staRotaSelect.ultima_compra;
      // this.varRotaLocal.email = this.staRotaSelect.email;
      // this.varRotaLocal.rota = this.staRotaSelect.rota;
      // this.varRotaLocal.senha = this.staRotaSelect.senha;
      // this.varRotaLocal.ativo = this.staRotaSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
